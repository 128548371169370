
@mixin ul-clear()
{
	padding:0px;
	margin:0px;
	list-style-type:none;
	li
	{
		padding:0px;
        margin:0px; 
        ul
        {
            padding:0px;
            margin:0px;
            list-style-type:none;  
        }
	}
}
@mixin animation($name:social-hover, $duration: 1s, $fill: forwards)
{
    animation-name: $name;
    animation-duration: $duration;
    animation-fill-mode: $fill; 
}