
@import "variables";
@import "mixin";
@import "animation";
@import "service";


@font-face {
    font-family: 'RalewayRegular';
    src: url('../fonts/Relaate0_5-SerifNarrow.eot');
    src: url('../fonts/Relaate0_5-SerifNarrow.eot') format('embedded-opentype'),
         url('../fonts/Relaate0_5-SerifNarrow.woff') format('woff'),
         url('../fonts/Relaate0_5-SerifNarrow.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
body
{
    font-family: "RalewayRegular"; 
}
.title-line
{
    margin-top:20px;
    font-size:82px;
    line-height:60px;
    text-align:center;
    text-transform:uppercase;
}
.logo-block
{
    margin-top:40px;
    margin-bottom:40px;
    text-align:center;
    img
    {
        height:60px;
    }
}
.text
{
    text-align:center;
    text-transform:uppercase;
    margin-top:40px;
    margin-bottom:25px;
}
nav
{
    @include ul-clear();
    display: flex;
    justify-content: center;
}
nav li
{
    margin-left:7px;
    margin-right:8px;
}
nav li a
{
    text-transform:uppercase;
    text-decoration:underline;
    color:#000;
}
nav li a:hover
{
    text-decoration:none;
    color:#000;
}
.bottom-text
{
    text-align:center;
    text-transform:uppercase;
    margin-top:40px;
    margin-bottom:40px;    
}
.pb40
{
    margin-bottom:40px;
}
.osn-text
{
    font-size: 28px;
    line-height:28px;
}
.picture-block
{
    display: flex;
    justify-content:flex-start;
    flex-wrap:wrap;
    &.rrr
    {
        .item
        {
            &:nth-of-type(n+12)
            {
                display:none;
            }
            &.show-all
            {
                display:flex;
            }
        }
    }
}
.picture-block .item
{
    width:16.666666%;
    position:relative;
    .additional-images
    {
        display:none;
    }

}
.picture-block .item a.main-img img
{
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray; /* IE 6-9 */
}
.picture-block .item a.main-img:hover img
{
    @include animation(picture-hover, 1s);
}
.picture-block .item .grey
{
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: url(grayscale.svg); /* Firefox 4+ */
    filter: gray; /* IE 6-9 */
}
.picture-block .item.show-all
{
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}
.tags-nav
{
    @include ul-clear();
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    margin-top:40px;
    margin-bottom:20px;
    li
    {  
        margin-left:6px;
        margin-right:6px;
        cursor:pointer;
        position: relative;
        padding-left:20px;
        padding-right:20px;
        margin-bottom:20px;
        // display: flex;
        // justify-content: center;
        // align-items: stretch;
        a
        {
            text-transform: uppercase;
            display:block;
            padding:10px 11px;
            background:#000;
            color:#fff;
            white-space:nowrap;

            &:hover
            {
                color:#fff;
                text-decoration:none;
            }
        }
        span
        {
            width:20px;
            position: absolute;
            top:0px;
            &.before
            {
                left:0px;
            }
            &.after
            {
                right:0px;
                // transform: rotate(180deg);
            }
        }
    }    
}
.modal-backdrop
{
    background:rgba(0,0,0, 0.7);
}
#exampleModal
{
    background:none;
    .modal-dialog
    {
        max-width:1280px;
        margin-top:0px;
        margin-bottom:0px;

    }
    .osn-text
    {
        text-align:center;
        margin-top:2rem;
        margin-bottom:2rem;
    }
    .modal-body
    {
        padding-top:0px;
        img
        {
            display:block;
            margin-bottom:1rem;
            &:last-of-type
            {
                margin-bottom:0px;
            }
        }

    }
    .modal-content
    {
        position:relative;
        border:none;
        border-radius:0px;
        .close-div
        {
            position: absolute;
            z-index:10;
            top:-1px;
            right:50px;
            .close
            {
                position:fixed;
                width:50px;
                height:50px;
                background:#000;
                opacity:1;
                text-shadow:none;
                color:#fff;
                padding:10px;
            }
        }
    }

    
}
@media(max-width:1399px)
{
    .title-line
    {
        font-size:72px;
        line-height:54px;        
    }
    .osn-text
    {
        font-size: 24px;
        line-height:24px;
    }
    .picture-block .item
    {
        width:25%;
    }
    .tags-nav
    {
        li
        {
            padding-left:18px;
            padding-right:18px;
            span
            {
                width:18px;
                img
                {
                    
                }
            }
        }
    }
    #exampleModal
    {
        .modal-dialog
        {
            max-width:90%;
        }
    }
}
@media(max-width:1199px)
{
    .title-line
    {
        font-size:60px;
        line-height:48px;        
    }
    .osn-text
    {
        font-size: 22px;
        line-height:22px;
    }
    .tags-nav
    {
        li
        {
            padding-left:17px;
            padding-right:17px;
            span
            {
                width:17px;
                img
                {
                    
                }
            }
        }
    }
}
@media(max-width:991px)
{
    .title-line
    {
        font-size:50px;
        line-height:40px;        
    }
    .osn-text
    {
        font-size: 20px;
        line-height:20px;
    }
    .picture-block .item
    {
        width:33.3333333%;
    }
    .tags-nav
    {
        li
        {
            padding-left:16px;
            padding-right:16px;
            span
            {
                width:16px;
                img
                {
                    
                }
            }
        }
    }

}
@media(max-width:767px)
{
    .title-line
    {
        font-size:40px;
        line-height:30px;        
    }
    .osn-text
    {
        font-size: 18px;
        line-height:18px;
    }
    .picture-block .item
    {
        width:50%;
    }
    .tags-nav
    {
        li
        {
            padding-left:16px;
            padding-right:16px;
            span
            {
                width:16px;
                img
                {

                }
            }
        }
    }
    #exampleModal
    {
        .modal-dialog
        {
            max-width:100%;
            margin:0px;
        }
    }
}